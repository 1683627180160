<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Notifications</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Notifications</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <section class="content" v-if="has_permission === true">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header">
            <div class="btn-toolbar pull-right">
              <el-dropdown split-button :trigger="'click'"
                           @command="handleSelectedRows"
                           type="primary" v-if="this.selected_row.length" class="mr-1">
                {{ this.selected_row.length }} {{ 'item' | pluralize(this.selected_row.length) }} selected
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click="bulkDelete()" command="delete">Delete</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <button class="btn btn-info" type="button" @click="showFilter = !showFilter">
                <i class="fa fa-filter"></i> Filter
              </button>
            </div>
            <div class="btn-toolbar pull-right">

            </div>
          </div>
          <form @submit.prevent="getItems" method="GET" novalidate>
            <div class="card-body" v-if="showFilter">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group form-row">
                    <label class="col-md-3 col-form-label" for="title">Title</label>
                    <div class="col-md-9">
                      <input type="text" id="title" class="form-control" v-model="filter.title" placeholder="Title">
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <!-- Date range -->
                  <div class="form-group form-row">
                    <label class="col-md-3 col-form-label" for="created_at">Created Date Range</label>
                    <div class="col-md-9">
                      <date-picker type="datetime" id="created_at" input-class="form-control"
                                   v-model="filter.created_at" range
                                   lang="en" format="YYYY-MM-DD hh:mm:ss" confirm
                                   value-type="YYYY-MM-DD hh:mm:ss"></date-picker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-9 offset-md-3">
                      <button type="submit" class="btn btn-common m-2px">Search</button>
                      <button type="reset" class="btn btn-warning" @click="resetFilter()">Reset</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>

          <div class="card-body table-responsive-md p-0">
            <table class="table">
              <thead>
              <tr>
                <th style="width: 10px">
                  <input type="checkbox" v-model="is_selected_all" @click="selectedRowAll()">
                </th>
                <th style="width: 10px">ID</th>
                <th>Title
                    <!--<sort-icons :orderKey="'title'"/>-->
                </th>
                <th>Description</th>
                <th>Link</th>
                <th>Created At</th>
                <th class="text-right">Action</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(val, index) in items.data">
                <td>
                  <input type="checkbox" v-model="selected_row" :value="val.id">
                </td>
                <td>{{ val.id }}</td>
                <td>{{ val.title }}</td>
                <td>{{ !_.isEmpty(val.description) ? val.description : 'NA' }}</td>
                <td>{{ !_.isEmpty(val.link) ? val.link : 'NA' }}</td>
                <td>{{ !_.isEmpty(val.created_at) ? $moment(val.created_at).fromNow() : 'NA' }}</td>
                <td class="text-right">
                  <router-link :to="{name: 'AuthNotificationShow', params: { id: val.id}}"
                               class="btn btn-info btn-sm m-2px">
                    <i class="fas fa-eye"></i>
                  </router-link>
                  <a class="btn btn-danger btn-sm m-2px" href="javascript:void(0)"
                     @click="destroy(val.id, index)"><i class="fas fa-trash"></i></a>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="col-12 p-4" v-if="_.isEmpty(items.data)">
              <div class="alert alert-default-warning" role="alert">
                No data found!
              </div>
            </div>
          </div>

          <div class="card-footer">
            <vue-pagination
              :pagination="items"
              v-if="items.total > items.per_page"
              @paginate="getItems()"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="content" v-if="has_permission === false">
      <div class="alert alert-danger alert-dismissible">
        <h5><i class="icon fas fa-ban"></i> Permission denied!</h5>
      </div>
    </section>
  </div>
</template>

<script>
import VuePagination from "@/components/partials/PaginationComponent";
import SortIcons from "@/components/utils/SortIcons";
import {AuthAPI} from "@/services/api";

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'NotificationList',
  components: {
    VuePagination, SortIcons, DatePicker
  },
  data: () => ({
    items: {
      total: 0,
      per_page: 1,
      from: 1,
      to: 0
    },
    showFilter: false,
    filter: {},
    is_loading: false,
    has_permission: true,
    selected_row: [],
    is_selected_all: false,
  }),
  methods: {
    getItems() {
      this.is_loading = true;
      let query = {...this.filter, ...this.$route.query}

      AuthAPI.notifications(query).then(items => {
        this.items = items;
      }).catch(error => {
        if (error.response.status === 403) {
          this.has_permission = false;
        }
      }).finally(() => {
        this.is_loading = false;
      });
    },
    resetFilter() {
      const query = Object.assign({}, this.$route.query);
      if (Object.keys(query).length > 0) {
        delete query.order_by
        delete query.order_key
        delete query.page
        this.$router.replace({query});
      }
      this.filter = {}
      this.selected_row = []
      this.is_selected_all = false
      this.getItems()
    },
    destroy: function (id, index) {
      this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.is_loading = true;
          AuthAPI.destroyNotifications(id)
            .then(response => {
              this.items.data.splice(index, 1);
              this.items.total--;
              this.$toaster.success(response.message);
            })
            .catch(error => {
              console.log("Could not delete this:: ", error);
              this.$swal.fire('Failed!', 'Failed to delete.', 'error');
            }).finally(() => {
            this.is_loading = false;
          });
        }
      });
    },
    selectedRowAll: function () {
      let isSelectedAll = this.is_selected_all = !this.is_selected_all;
      if (isSelectedAll) {
        var items = [];
        this.items.data.forEach(function (item) {
          items.push(item.id);
        })
        this.selected_row = items;
      } else {
        this.selected_row = [];
      }
    },
    handleSelectedRows(command) {
      if (command === 'delete') {
        console.log('Selected ID to Delete:: ', this.selected_row)
        this.bulkDelete(this.selected_row);
      }
    },
    bulkDelete(ids) {
      this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.is_loading = true;
          AuthAPI.destroySelectedRows({'ids': ids})
            .then(response => {
              /*this.items.data.splice(index, 1);
              this.items.total--;*/
              this.getItems();
              this.$toaster.success(response.message);
            })
            .catch(error => {
              console.log("Could not delete this:: ", error);
              this.$swal.fire('Failed!', 'Failed to delete.', 'error');
            }).finally(() => {
            this.is_loading = false;
          });
        }
      });
    }
  },
  mounted: function () {
    this.getItems();
    this.$root.$on('sort-data', () => {
      this.$root.$emit('show-icon');
      this.getItems();
    });
  }
}
</script>
